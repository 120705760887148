export default [
  // Models
  {
    name: "ramenShopModel",
    type: "gltfModel",
    path: "models/ramenShop/glTF/ramenShop.gltf",
  },
  {
    name: "ramenHologram",
    type: "gltfModel",
    path: "models/ramenShop/glTF/ramenHologram.gltf",
  },
  // Baked Textures
  {
    name: "ramenShopBakedTexture",
    type: "KTX2Texture",
    path: "textures/baked/ramenShopBaked1024.ktx2",
  },
  {
    name: "machinesBakedTexture",
    type: "KTX2Texture",
    path: "textures/baked/machinesBaked1024.ktx2",
  },

  {
    name: "floorBakedTexture",
    type: "KTX2Texture",
    path: "textures/baked/floorBaked1024-kopia.ktx2",
  },
  {
    name: "blogBakedTexture",
    type: "texture",
    path: "textures/baked/blogBaked.png",
  },

  {
    name: "miscBakedTexture",
    type: "texture",
    path: "textures/baked/miscBaked1024-moje.png",
  },

  {
    name: "graphicsBakedTexture",
    type: "KTX2Texture",
    path: "textures/baked/graphicsBaked512.ktx2",
  },
  // Matcaps
  {
    name: "dishMatcapTexture",
    type: "KTX2Texture",
    path: "textures/matcaps/dishMatcap.ktx2",
  },
  {
    name: "fanMatcapTexture",
    type: "KTX2Texture",
    path: "textures/matcaps/fanMatcap.ktx2",
  },
  {
    name: "lightMatcapTexture",
    type: "texture",
    path: "textures/matcaps/lightMatcap.png",
  },
  {
    name: "neonBlueMatcapTexture",
    type: "KTX2Texture",
    path: "textures/matcaps/neonBlueMatcap.ktx2",
  },
  {
    name: "neonGreenMatcapTexture",
    type: "KTX2Texture",
    path: "textures/matcaps/neonGreenMatcap.ktx2",
  },
  // Arcade Screens
  {
    name: "arcadeScreenDefaultTexture",
    type: "KTX2Texture",
    path: "textures/screens/arcadeScreens/arcadeScreenDefault-moje.ktx2",
  },

  {
    name: "arcadeScreenCreditsTexture",
    type: "KTX2Texture",
    path: "textures/screens/arcadeScreens/arcadeScreenCredits-moje.ktx2",
  },

  {
    name: "arcadeScreenThanksTexture",
    type: "KTX2Texture",
    path: "textures/screens/arcadeScreens/arcadeScreenThanks-moje.ktx2",
  },

  // Easel
  {
    name: "easelTouchTexture",
    type: "KTX2Texture",
    path: "textures/screens/easel/easelTouch-moje.ktx2",
  },

  {
    name: "easelClickTexture",
    type: "KTX2Texture",
    path: "textures/screens/easel/easelClick-moje.ktx2",
  },

  // About Me Screens
  {
    name: "bigScreenDefaultTexture",
    type: "KTX2Texture",
    path: "textures/screens/aboutMeScreens/bigScreenDefault-moje.ktx2",
  },

  {
    name: "bigScreenAboutMeTexture",
    type: "KTX2Texture",
    path: "textures/screens/aboutMeScreens/bigScreenAbout-moje.ktx2",
  },

  {
    name: "bigScreenSkillsTexture",
    type: "KTX2Texture",
    path: "textures/screens/aboutMeScreens/bigScreenSkills2-moje.ktx2",
  },

  {
    name: "bigScreenExperienceTexture",
    type: "KTX2Texture",
    path: "textures/screens/aboutMeScreens/bigScreenExperience-moje.ktx2",
  },

  {
    name: "bigScreenAboutMeMobileTexture",
    type: "KTX2Texture",
    path: "textures/screens/aboutMeScreens/bigScreenAboutMobile-moje.ktx2",
  },

  {
    name: "bigScreenSkillsMobileTexture",
    type: "KTX2Texture",
    path: "textures/screens/aboutMeScreens/bigScreenSkillsMobile-moje.ktx2",
  },

  {
    name: "bigScreenExperienceMobileTexture",
    type: "KTX2Texture",
    path: "textures/screens/aboutMeScreens/bigScreenExperienceMobile-moje.ktx2",
  },

  // Projects
  {
    name: "vendingMachineDefaultTexture",
    type: "KTX2Texture",
    path: "textures/screens/vendingMachineScreens/vendingMachineDefault-moje.ktx2",
  },

  {
    name: "vendingMachineMenuTexture",
    type: "KTX2Texture",
    path: "textures/screens/vendingMachineScreens/vendingMachineMenu-moje-nowe.ktx2",
  },

  {
    name: "project1Texture",
    type: "KTX2Texture",
    path: "textures/screens/vendingMachineScreens/project1-moje.ktx2",
  },

  {
    name: "project2Texture",
    type: "KTX2Texture",
    path: "textures/screens/vendingMachineScreens/project2-moje.ktx2",
  },

  {
    name: "project3Texture",
    type: "KTX2Texture",
    path: "textures/screens/vendingMachineScreens/project3-moje.ktx2",
  },

  {
    name: "project4Texture",
    type: "KTX2Texture",
    path: "textures/screens/vendingMachineScreens/project4-moje.ktx2",
  },

  {
    name: "project5Texture",
    type: "KTX2Texture",
    path: "textures/screens/vendingMachineScreens/project5-moje.ktx2",
  },

  // {
  //     name: 'project6Texture',
  //     type: 'KTX2Texture',
  //     path: 'textures/screens/vendingMachineScreens/project6.ktx2'
  // },

  // {
  //     name: 'project7Texture',
  //     type: 'KTX2Texture',
  //     path: 'textures/screens/vendingMachineScreens/project7.ktx2'
  // },

  // {
  //     name: 'project8Texture',
  //     type: 'KTX2Texture',
  //     path: 'textures/screens/vendingMachineScreens/project8.ktx2'
  // },

  // Videos
  {
    name: "littleTVScreenVideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/littleTVScreen.mp4",
  },
  {
    name: "tallScreenVideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/tallScreen-moje.mp4",
  },
  {
    name: "tvScreenVideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/tvScreen-moje.mp4",
  },
  {
    name: "smallScreen5VideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/smallScreen5.mp4",
  },
  {
    name: "smallScreen4VideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/smallScreen4-moje.mp4",
  },
  {
    name: "smallScreen3VideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/smallScreen3-moje.mp4",
  },

  // smallScreen1 carousel

  {
    name: "smallScreen1VideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/smallScreen6.mp4",
  },

  // smallScreen2 carousel

  {
    name: "smallScreen2VideoTexture",
    type: "videoTexture",
    path: "textures/videosTextures/smallScreen8-moje.mp4",
  },

  // sideScreen

  {
    name: "sideScreen1Texture",
    type: "KTX2Texture",
    path: "textures/screens/sideScreens/sideScreen1-moje.ktx2",
  },
  {
    name: "sideScreen2Texture",
    type: "KTX2Texture",
    path: "textures/screens/sideScreens/sideScreen2-moje.ktx2",
  },
  {
    name: "sideScreen3Texture",
    type: "KTX2Texture",
    path: "textures/screens/sideScreens/sideScreen3-moje.ktx2",
  },
];
